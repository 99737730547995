import * as Yup from 'yup';

const validationSchema = Yup.object({
	recipeName: Yup.string().required('Required'),
	description: Yup.string().max(255, 'Must be 255 characters or less'),

	/**
	 * Category is required and must be an array with at least one item
	 * Categories can to be combined. Unless it is ORIGINAL or TEST, it can be combined with other categories.
	 */
	category: Yup.array()
		.min(1, 'Required')
		.test(
			'category',
			'Categories cannot be selected at the same time, except ORIGINAL and TEST with others',
			(value) => {
				if (value && value.length) {
					// Filter out "ORIGINAL" and "TEST" to see if there are other categories selected
					const filteredCategories = value.filter((category) => category !== 'ORIGINAL' && category !== 'TEST');

					// If there are other categories selected alongside "ORIGINAL" or "TEST", it's valid
					// If there's only one category (or none) after filtering, it's also valid
					if (filteredCategories.length <= 1) {
						return true;
					}

					// If "ORIGINAL" or "TEST" is selected with another category, it's valid
					// This check is redundant given the logic above but added for clarity
					const isOriginalOrTestSelected = value.includes('ORIGINAL') || value.includes('TEST');
					if (isOriginalOrTestSelected && filteredCategories.length === 1) {
						return true;
					}

					// If there are multiple categories selected that are not "ORIGINAL" or "TEST", it's invalid
					return false;
				}
				// If value is not provided or empty, the previous .min(1, 'Required') check will handle it
			},
		),

	/**
	 * this checks on following conditions:
	 * 1. there must be at least one smoothie part
	 * 2. the sum of all percentages must be 100
	 * 3. each percentage must be between 0 and 100 inclusive
	 * 4. each flavorId must be unique in the array
	 */
	smoothieParts: Yup.array()
		.min(1, 'min 1 smoothie part')
		.of(
			Yup.object().shape({
				flavorId: Yup.string().required('Required'),
				percentage: Yup.number()
					.required('Required')
					.min(1, 'Must be greater than or equal to 0')
					.max(100, 'Must be less than or equal to 100'),
			}),
		)
		.test('sum-of-percentages', 'Sum of percentages must be 100', (value) => {
			if (!value) return false;

			if (value) {
				const sumOfPercentages = value.reduce((accumulator, currentValue) => {
					return accumulator + currentValue.percentage;
				}, 0);

				return sumOfPercentages === 100;
			}
		})
		.test('unique-flavorIds', 'Flavor IDs must be unique', (value) => {
			if (value) {
				const flavorIds = value.map((part) => part.flavorId);
				const uniqueFlavorIds = [...new Set(flavorIds)];
				return uniqueFlavorIds.length === flavorIds.length;
			}
		}),

	/**
	 * Water ratio is required and is a number between 0.1 and 1.5 inclusive
	 * if category is soup than the value should be 0.6
	 * if category is smoothie than the value should be 0.9
	 */
	waterRatio: Yup.number()
		.min(0.1, 'Must be greater than or equal to 0.1')
		.max(1.5, 'Must be less than or equal to 1.5')
		.required('Required'),

	image: Yup.string().required('Required'),
	icon: Yup.string(),
	steamConfiguration: Yup.number()
		.min(0, 'Must be greater than or equal to 0')
		.max(50000, 'Must be less than or equal to 50000')
		.required('Required')
		.integer('Must be an integer'),
	blendConfiguration: Yup.number()
		.min(0, 'Must be greater than or equal to 0')
		.max(50000, 'Must be less than or equal to 50000')
		.required('Required')
		.integer('Must be an integer'),
	weightRatio: Yup.number()
		.min(0.1, 'Must be greater than or equal to 0.1')
		.max(2.0, 'Must be less than or equal to 2.0')
		.required('Required'),
});

export default validationSchema;
