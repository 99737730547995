import React, { useState, useEffect } from 'react';
import S3 from 'aws-sdk/clients/s3';

import { TabMenu } from 'primereact/tabmenu';
import { Button } from 'primereact/button';
import MachineDetail from './MachineDetail';
import MachineIngredient from './MachineIngredient';
import MachineMovies from './MachineMovies';
import MachineRecipesTest from './MachineRecipesTest';

import './MachineInfo.css';
import Alert from '../utils/Alert';
import MachineStatus from './MachineStatus';

const MachineInfo = (props) => {
	const [machineName] = useState(
		props.location.state.customerName ? props.location.state.customerName : props.location.state.name,
	);
	const [changeWarning, setChangeWarning] = useState(false);
	const [activeItem, setActiveItem] = useState('Details');
	const [activeIndex, setActiveIndex] = useState(0);
	const [showStatusTab, setShowStatusTab] = useState(false);
	const [screenshotData, setScreenshotData] = useState(null);

	const items = [
		{
			label: 'Details',
			icon: 'pi pi-fw pi-info',
			command: () => {
				setActiveItem('Details');
				setActiveIndex(0);
			},
		},
		{
			label: 'Flavors',
			icon: 'pi pi-fw pi-pencil',
			command: () => {
				setActiveItem('Flavors');
				setActiveIndex(1);
			},
		},
		{
			label: 'Recipes',
			icon: 'pi pi-fw pi-list',
			command: () => {
				setActiveItem('Recipes');
				setActiveIndex(2);
			},
		},
		{
			label: 'Movies',
			icon: 'pi pi-fw pi-video',
			command: () => {
				setActiveItem('Movies');
				setActiveIndex(3);
			},
		},
	];

	const statusLabel = {
		label: 'Status',
		icon: 'pi pi-fw pi-check-circle',
		command: () => {
			setActiveItem('Status');
			setActiveIndex(4);
		},
	};

	useEffect(() => {
		const machineId = props.location.state.machineId;

		const s3 = new S3({
			accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
			secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
			region: process.env.REACT_APP_AWS_REGION,
		});

		// get the image for the current machine using the id from the props
		const fetchImage = async () => {
			try {
				const data = await s3
					.getObject({
						Bucket: 'static.alberts.be',
						Key: `machine-screen-states/${machineId}.png`,
					})
					.promise();

				if (data) {
					setShowStatusTab(true);
					setScreenshotData(data);
				}
			} catch (err) {
				console.log('err', err);
			}
		};

		fetchImage();

		return () => {
			setShowStatusTab(false);
			setScreenshotData(null);
		};
	}, [props.location.state.machineId]);

	return (
		<div>
			<div className="header-menu">
				<div className="menu">
					<Button
						className="menu-button"
						onClick={() => props.history.goBack()}
						icon="pi pi-arrow-left"
						severity="secondary"
					/>
					<h3 className="machine-name">{machineName}</h3>
				</div>
				<TabMenu
					model={showStatusTab ? [...items, statusLabel] : items}
					onTabChange={(e) => {
						console.log(e.value.label);
						setActiveItem(e.value.label);
					}}
					activeIndex={activeIndex}
				></TabMenu>
			</div>

			<Alert />

			<div className="">
				{activeItem === 'Details' && <MachineDetail {...props}></MachineDetail>}
				{activeItem === 'Flavors' && (
					<MachineIngredient
						{...props}
						changeWarning={changeWarning}
						setChangeWarning={setChangeWarning}
					></MachineIngredient>
				)}
				{activeItem === 'Recipes' && <MachineRecipesTest {...props}></MachineRecipesTest>}
				{activeItem === 'Movies' && <MachineMovies {...props}></MachineMovies>}
				{showStatusTab && activeItem === 'Status' && (
					<MachineStatus {...props} screenshotData={screenshotData}></MachineStatus>
				)}
			</div>
		</div>
	);
};

export default MachineInfo;
