import React from 'react';

import './MachineStatus.css';
import { Card, Image, Label } from 'semantic-ui-react';

const MachineStatus = (props) => {
	const currentDateTime = new Date();

	return (
		<div className="container-screenshot">
			<Card>
				<Card.Content extra>
					{currentDateTime - props.screenshotData.LastModified > 3600000 && (
						<div>
							<Label basic color="red" pointing="below" size="mini">
								<i className="exclamation triangle icon"></i>
								Outdated: {'>'} 1 hour
							</Label>
						</div>
					)}
					<Label
						style={{ float: 'left' }}
						color={currentDateTime - props.screenshotData.LastModified > 3600000 ? 'red' : 'green'}
					>
						<i className="clock icon"></i>
						{props.screenshotData.LastModified.toLocaleTimeString()}
					</Label>
					<Label style={{ float: 'right' }}>
						<i className="calendar icon"></i>
						{props.screenshotData.LastModified.toLocaleDateString()}
					</Label>
				</Card.Content>
				<Image
					src={`https://s3.eu-west-1.amazonaws.com/static.alberts.be/machine-screen-states/${props.location.state.machineId}.png`}
					alt="machine screenshot"
					className="screenshot"
				/>
				<Card.Content extra>
					<a
						href={`https://s3.eu-west-1.amazonaws.com/static.alberts.be/machine-screen-states/${props.location.state.machineId}.png`}
						target="_blank"
						rel="noopener noreferrer"
					>
						<i className="external alternate icon"></i>
						Full screen
					</a>
				</Card.Content>
			</Card>
		</div>
	);
};

export default MachineStatus;
